import request from '@/utils/request'

// 待审核列表
export const toBeReviewedAPI = (data) => {
  return request({
    url: '/noshenlList',
    method: 'POST',
    data
  })
}

// 已审核
export const auditedAPI = (data) => {
  return request({
    url: '/shenlList',
    method: 'POST',
    data
  })
}

// 报名详情
export const registrationDetailsAPI = (data) => {
  return request({
    url: '/applyInfo',
    method: 'POST',
    data
  })
}

// 报名提交
export const signUpAndSubmitAPI = (data) => {
  return request({
    url: '/applySave',
    method: 'POST',
    data
  })
}

// 已审核导出
export const reviewExportAPI = (params) => {
  return request({
    url: '/report_shen',
    method: 'GET',
    params
  })
}

// 系部-专业-报名人数统计导出
export const departmentsSpecializedExportAPI = (params) => {
  return request({
    url: '/statisticsXslx',
    method: 'GET',
    params
  })
}

// 区县-报名人数统计导出
export const districtAndCountyExportAPI = (params) => {
  return request({
    url: '/countyXslx',
    method: 'GET',
    params
  })
}
// 系部-经办人-报名人数统计导出
export const exportOfTheDepartmentAPI = (params) => {
  return request({
    url: '/handlerXslx',
    method: 'GET',
    params
  })
}

// 已审核删除
export const reviewDeleteAPI = (params) => {
  return request({
    url: '/applyDel',
    method: 'GET',
    params
  })
}

// 重置录取通知书
export const rechargeNoticeAPI = (params) => {
  return request({
    url: '/reset_letter',
    method: 'GET',
    params
  })
}
